<template>
  <section
    :class='{
      wall: true,
      "wall--not-found": articles.length <= 0,
    }'>
      <div class='wall__buttons' v-if='articles.length > 0'>
        <a class='wall__counter'>{{foundText}}</a>
        <div class='wall__found found sortText'>
          Сортировать:
          <span class='found__link' @click='changeSort'>{{sortText}}</span>
        </div>
        <div class='wall__found found'>
          Группировать:
          <span class='found__link' @click='changeDup'>{{dupText}}</span>
        </div>
      </div>
      <div
      :class='{
        "wall__list": true,
        "wall__list--is-loading": isLoading,
      }'
      v-if='articles.length > 0'>
        <div class='wall__item' v-for='article in articles' :key='article.id'>
          <Article :articleData='article' :time='now'/>
        </div>
      </div>
      <div class='not-found' v-if='isEmptyQuery && articlesCount === 0'>
        <h3 class='not-found__title'>Введите поисковый запрос</h3>
      </div>
      <div class='not-found' v-if='[400, 404, 429, 500].includes(resultCode)'>
        <h3 class='not-found__title' v-html='messages[resultCode]'></h3>
      </div>
      <template v-if='articles.length > 0 && articlesCount !== 0 && articles.length === articlesCount && articlesLimit > articlesCount'>
        <div class='not-found'>
          <h3
            class='not-found__title'
            v-if='time !== "" && time !== "all"'>
            Показаны все записи<br>
            Чтобы увидеть больше попробуйте <a @click.prevent='removeTime' href='#'>снять ограничение по времени</a></h3>
          <h3
            class='not-found__title'
            v-else-if='platform !== ""'>
            Показаны все записи<br>
            Чтобы увидеть больше попробуйте <a @click.prevent='removeSocial' href='#'>поискать во всех социальных сетях</a></h3>
          <h3
            class='not-found__title load-more__link'
            v-else-if='articlesCount === articles.length'
            >Показаны все записи</h3>
        </div>
      </template>
      <Loader :class='{
        "wall__loader": true,
        "wall__loader--is-loading": isLoading,
      }' ref='loader'/>
  </section>
</template>
<script>
import Article from '@/components/ui/article/article.vue';
import Loader from '@/components/ui/loader/loader.vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import { declOfNum } from '@/api/utils';
/**
 * Компонент отображающий стену материалов
 */
export default {
  name: 'Wall',
  created() {
    document.title = 'Крибрум: Поиск по социальным сетям';
    window.addEventListener('scroll', this.handleScroll);
    this.timer = window.setInterval(this.updateTime, 10 * 60 * 1000);

    this.$store.dispatch('userInfo');
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
    window.clearInterval(this.timer);
  },
  mounted() {
    this.$nextTick(function () {
      this.$refs.loader.$el.style.top = `${this.getTopPositionLoader() + window.scrollY}px`;
    });
  },
  components: {
    Article,
    Loader,
  },
  computed: {
    ...mapGetters([
      'getCurQueryParam',
    ]),
    ...mapState({
      isLoading: state => state.search.isLoading,
      articles: state => state.search.articles,
      articlesCount: state => state.search.result.count,
      articlesLimit: state => state.search.result.limit,
      resultCode: state => state.search.result.code,
      query: state => state.search.curQuery.query,
      author: state => state.search.curQuery.author,
      platform: state => state.search.curQuery.platform,
      time: state => state.search.curQuery.time,
      total: state => state.search.result.total,
    }),
    foundText() {
      let count = this.total ? this.total : 0;
      if (count < 1000) {
        return `${declOfNum(count, ['Найдена', 'Найдено', 'Найдено'])} ${count} ${declOfNum(count, ['запись', 'записи', 'записей'])}`;
      }
      if (count < 1000000) {
        count = Math.round(count / 1000);
        return `${declOfNum(count, ['Найдена', 'Найдено', 'Найдено'])} ${count} ${declOfNum(count, ['тысяча записей', 'тысячи записей', 'тысяч записей'])}`;
      }

      count = Math.round(count / 1000000);
      return `${declOfNum(count, ['Найден', 'Найдено', 'Найдено'])} ${count} ${declOfNum(count, ['миллион записей', 'миллиона записей', 'миллионов записей'])}`;
    },
    sort() {
      return this.$store.getters.getCurQueryParam('order');
    },
    sortText() {
      return this.sort === 'ptime' ? 'сначала новые' : 'сначала старые';
    },
    dup() {
      return this.$store.getters.getCurQueryParam('dup');
    },
    dupText() {
      return this.dup === 0 ? 'похожие' : 'никакие';
    },
    isEmptyQuery() {
      if (this.query && this.query.length) {
        return false;
      }

      if (this.author && this.author.length && this.platform && this.platform.length) {
        return false;
      }

      return true;
    },
    isSearchPage() {
      return this.$route.path === '/search';
    },
  },
  watch: {
    isLoading() {
      this.$refs.loader.$el.style.top = `${this.getTopPositionLoader() + window.scrollY}px`;
    },
  },
  methods: {
    ...mapActions([
      'search',
      'userInfo',
    ]),
    changeSort() {
      const value = this.sort === 'iptime' ? 'ptime' : 'iptime';
      this.$store.commit('UPDATE_PARAM', { field: 'order', value });

      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: this.platform,
          time: this.time,
          dup: this.dup,
          order: value,
        },
      });
      this.$store.dispatch('search');
    },
    changeDup() {
      const value = this.dup === 0 ? 1 : 0;
      this.$store.commit('UPDATE_PARAM', { field: 'dup', value });

      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: this.platform,
          time: this.time,
          order: this.order,
          dup: value
        },
      });
      this.$store.dispatch('search');
    },
    getTopPositionLoader() {
      const isMobile = window.outerWidth < 960;
      const isSearchPage = this.isSearchPage || false;
      const windowHeight = window.innerHeight;
      const headerSelector = isMobile && isSearchPage ? '.popup--search-js-size' : '.header';
      const headerHeight = document.querySelector(headerSelector).offsetHeight;
      const mainHeight = document.querySelector('.body').offsetHeight;
      const footerHeight = document.querySelector('.footer').offsetHeight;

      const isFooterVisible = (headerHeight + mainHeight + footerHeight) === windowHeight;
      if (isFooterVisible) {
        const bodyHeight = windowHeight - (headerHeight + footerHeight);
        const bodyCenter = bodyHeight / 2;
        return bodyCenter - 18;
      }

      const centerBodyAndFooter = (windowHeight - headerHeight) / 2;
      return centerBodyAndFooter - 18; // 18 - половина высоты лоадера
    },
    handleScroll() {
      const windowHeight = window.innerHeight;
      const headerHeight = document.querySelector('.header').offsetHeight;
      const mainHeight = document.querySelector('.body').offsetHeight;
      const footerHeight = document.querySelector('.footer').offsetHeight;

      const isFooterVisible = (headerHeight + mainHeight + footerHeight) === windowHeight;
      let visibleHeight = 0;
      if (isFooterVisible) {
        visibleHeight = windowHeight - (headerHeight + footerHeight);
      } else {
        visibleHeight = windowHeight - headerHeight;
      }

      const bodyHeight = document.body.offsetHeight;
      const isDocumentEnd = bodyHeight - (window.scrollY + visibleHeight + headerHeight) <= 50;
      let isDataForRequestExist = false;
      if (this.query && this.query.length > 0) {
        isDataForRequestExist = true;
      }
      if (this.author && this.author.length > 0 && this.platform && this.platform.length > 0) {
        isDataForRequestExist = true;
      }

      if (isDocumentEnd && !this.isLoading && isDataForRequestExist) {
        this.$store.dispatch('search');
      }
    },
    getTime() {
      return Date.now() || new Date().getTime();
    },
    updateTime() {
      this.now = this.getTime();
    },
    removeTime() {
      this.$store.commit('UPDATE_PARAM', { field: 'time', value: 'all' });
      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: this.platform,
          time: 'all',
          order: this.sort,
        },
      });
      this.$store.dispatch('search');
    },
    removeSocial() {
      this.$store.commit('UPDATE_PARAM', { field: 'platform', value: '' });
      this.$router.push({
        name: 'search',
        query: {
          query: this.query,
          author: this.author,
          platform: '',
          time: this.time,
          order: this.sort,
        },
      });
      this.$store.dispatch('search');
    },
  },
  data() {
    return {
      now: this.getTime(),
      timer: '',
      messages: {
        400: '<h3>Пустой запрос</h3><p>Пожалуйста, измените параметры запроса</p>',
        404: '<h3>По вашему запросу ничего не найдено</h3><p>Пожалуйста, измените поисковый запрос',
        429: '<h3>Вы превысили максимальное количество запросов</h3><p>Пожалуйста, подождите и повторите запрос.<br>Вы можете <a href="/login">авторизоваться</a> на сайте, чтобы снять ограничение на число поисковых запросов.</p>',
        500: '<h3>Произошла неизвестная ошибка</h3><p>Пожалуйста, попробуйте позже</p>',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
  $block: '.wall';
  #{$block} {
    flex: 1 0 100%;
    position: relative;
    &--not-found {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__list {
      position: relative;
      &--is-loading {
        &:before {
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          z-index: 11;
          background: rgba(255, 255, 255, 0.75);
          content: ' ';
        }
      }
    }
    &__item {
      border-bottom: 1px solid rgba(#808080, 0.2);
      &:last-of-type {
        border-bottom: 0;
      }
    }
    &__loader {
      display: none;
      position: absolute;
      left: calc(50% - 18px);
      z-index: 11;
      &--is-loading {
        display: block;
      }
    }
    &__buttons {
      text-align: center;
    }
    &__counter {
      display: block;

      color: #808080;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
    }
  }

  @media all {
    #{$block}{
      padding: 0 16px;
      &__buttons {
        margin: 16px 0;
      }
      &__counter {
        margin-bottom: 4px;
      }
    }
  }
  @media all and (min-width: 960px) {
    #{$block}{
      padding: 0 16px;
      &__buttons {
        padding: 0 196px 0 180px;

        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
      }
    }
    .sortText {
      margin-left: auto;
      margin-right: 16px;
    }
  }
  @media all and (min-width: 1366px) {
    #{$block}{
      padding: 0;
    }
  }
  .found {
    color: #808080;
    font-size: 16px;
    font-weight: 400;
    &__link {
      color: #5a7380;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .not-found {
    padding: 16px 0 32px 0;
    text-align: center;
    &__title {
      color: #808080;
      font-size: 16px;
      font-weight: 400;
      a {
        color: #5a7380;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
</style>
